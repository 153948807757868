.schedule-date-input, .schedule-date-select {
  width: 200px!important;
  flex: none!important;
}
@media screen and (max-width: 776px){
  .schedule-date-input {
    width: 100% !important;
  }
  .schedule-date-select {
    width: calc(100% - 100px) !important;
  }
  .rbc-event {
    padding-left: 4px!important;
  }
  .rbc-event-content {
    text-overflow: clip!important;
  }
}


