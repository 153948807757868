.red-color {
  color: $red;
}

.grey-color {
  color: $gray-600
}

.brand-color {
  color: $brand
}
.brand-secondary-color {
  color: $brand-secondary;
}
.Burger {
  padding-bottom: 7px!important;
}
input::-webkit-search-cancel-button {
  display: none;
}
.btn-secondary {
  color: $brand-secondary;
  border-color: $brand-secondary;
  background-color: #fff;
  font-size: 1rem;
}
.disabled-content {
  pointer-events: none;
  opacity: 0.5;
}
.input-height {
  height: calc(1.5em + 0.75rem + 2px);
}
.width-auto {
  width: auto;
}
.no-padding-col-small {
  padding-left: 0;
  padding-right: 0;
}
.react-datepicker-time__caption {
  text-align: center;
}
.react-datepicker-time__input {
  text-align: center;
}
@media screen and (max-width: 500px){
  .react-datepicker-popper {
    transform: none !important;
  }
}
.bg-brand {
  background-color: $brand-secondary;
}
.bg-zumzi {
  background-image: url('./images/bg_down_small.png');
  background-size: cover;
  background-position: left;
}
.flex-container {
  .ap-input-icon svg {
    top: calc(50% - 4px) !important;
  }
}
